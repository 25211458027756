import React, { useEffect, useState } from "react"

import { ScrollTrigger, gsap } from "gsap/all"
import classNames from "classnames"

import { FOOTNOTE_SYMBOLS } from "constants/Site"
import { useWindowSize } from "hooks/useWindowSize"

import styles from "./TolerabilityChart.module.scss"

gsap.registerPlugin(ScrollTrigger)

const TolerabilityChart = ({ disorders, studies }) => {
  let disorderCount = 0
  let [scrollTrigger, setScrollTrigger] = useState()
  const { width } = useWindowSize()

  useEffect(() => {

    setScrollTrigger(
      ScrollTrigger.create({  
        trigger: "#chart",
        //start: "top-=1rem top+=109px",
        start: "top top",
        pin: ".pinHeadings",
        //end: "bottom bottom-=240px",
        end: "bottom bottom-=240px",
        anticipatePin: 1,
        onToggle: self => {

            if(self.isActive){
              if(document.querySelector('.pinHeadings')){
                document.querySelectorAll('.pinHeadings')[0].classList.add('is-active');
              }
            } else {
              if(document.querySelector('.pinHeadings')){
                document.querySelectorAll('.pinHeadings')[0].classList.remove('is-active');
                document.querySelectorAll('.pinHeadings')[0].classList.remove('chart--show');
                document.querySelectorAll('.pinHeadings')[0].classList.remove('chart--disappear');
            }
        }
      },
        onUpdate: self => {
              if(self.direction === -1){
                if(document.querySelector('.pinHeadings')){
                  document.querySelectorAll('.pinHeadings')[0].classList.add('chart--show');
                  document.querySelectorAll('.pinHeadings')[0].classList.remove('chart--disappear');
                }
              } else {
                if(document.querySelector('.pinHeadings')){
                  document.querySelectorAll('.pinHeadings')[0].classList.add('chart--disappear');
                  document.querySelectorAll('.pinHeadings')[0].classList.remove('chart--show');
                }
              }
        }   
      })
    )
    ScrollTrigger.refresh()
  }, [setScrollTrigger])

  useEffect(() => {
    if (width < 992) {
      scrollTrigger && scrollTrigger.disable()
    } else {
      scrollTrigger && scrollTrigger.enable()
    }

    // return () => scrollTrigger && scrollTrigger.disable()
  }, [width, scrollTrigger])

  const disordersObject = disorders.map(({ disorders, header }, index) => {
    return {
      header,
      disorders: disorders.map((disorder) => {
        const index = disorderCount++
        return {
          header: { ...disorder },
          values: Array.prototype.concat.apply([], studies
            .map(({ sample, control }) => [
              `${sample.disorders[index].value} (${sample.disorders[index].percent}%)`,
              `${control.disorders[index].value} (${control.disorders[index].percent}%)`,
            ])),
        }
      }),
    }
  })

  return (
    <div id={"chart"} className={classNames([styles.chart])}>
      <div className={classNames([styles.headings, "pinHeadings"])}>
        {studies.map(({ header, subheader, sample, control }, index) => {
          return (
            <div className={styles.heading} key={index}>
              <div className={styles.header}>
                <div>
                  {header.title}
                  {header.footnotes && (
                    <sup className={styles.footnotes}>
                      {header.footnotes
                        .map((footnote) => FOOTNOTE_SYMBOLS[footnote])
                        .join(",")}
                    </sup>
                  )}
                  {"\n"}
                  <span
                    className={classNames([styles.subheader], {
                      [styles.desktopOnly]: subheader.mobileTitle,
                    })}
                  >
                    {subheader.title}
                  </span>
                  {subheader.mobileTitle && (
                    <span
                      className={classNames([
                        styles.subheader,
                        styles.mobileOnly,
                      ])}
                    >
                      {subheader.mobileTitle}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.groups}>
                <div className={styles.group}>
                  <div>
                    <p>{sample.header}</p>
                    <p>{`n=(${sample.size})`}</p>
                  </div>
                </div>
                <div className={styles.group}>
                  <div>
                    <p>{control.header}</p>
                    <p>{`n=(${control.size})`}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.data}>
          {disordersObject.map(({ disorders, header }, index) => {
            return (
              <div key={index} className={styles.disordersContainer}>
                <div className={styles.disordersHeader}>
                  <span>{header}</span>
                </div>
                <div className={styles.disordersGroup}>
                  {disorders.map(({ header, values }, index) => {
                    return (
                      <div key={index} className={styles.disorderContainer}>
                        <div className={styles.valueHeader}>
                          <p>
                            {header.title}
                            {header.footnotes?.length > 0 && (
                              <sup className={styles.footnotes}>
                                {header.footnotes
                                  .map((footnote) => FOOTNOTE_SYMBOLS[footnote])
                                  .join(",")}
                              </sup>
                            )}
                          </p>
                        </div>
                        <div className={styles.valuesContainer}>
                          {values.map((value, index) => {
                            return (
                              <div key={index} className={styles.value}>
                                {value}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TolerabilityChart
