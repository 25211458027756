import React from "react"
import { Container } from "react-bootstrap"

import styles from "./PageTitle.module.scss"

const PageTitle = ({ title, references = [] }) => {
  console.log('references ', references);
  return (
    <Container fluid as={"section"} className={styles.pageTitle}>
      <Container>
        <h1 className={styles.title}>
          {title}
          {references.length > 0 && (
            <sup className={styles.references}>{references.join(",")}</sup>
          )}
        </h1>
      </Container>
    </Container>
  )
}

export default PageTitle
