import React from "react"
import { Container } from "react-bootstrap"

import classNames from "classnames"
import { graphql } from "gatsby"

import { StateProvider } from "state/state"
import { reducer, initialState } from "state/reducer"
import tolerabilityData from "constants/TolerabilityData.json"
import { FOOTNOTE_SYMBOLS } from "constants/Site"

import Layout from "components/Layout"
import SEO from "components/Seo"
import PageTitle from "components/PageTitle/PageTitle"
import TolerabilityChart from "components/TolerabilityChart/TolerabilityChart"

import "../styles/main.scss"
import styles from "../styles/pages/tolerability.module.scss"

const REFERENCES_HEADER = "References"
const REFERENCES = [
  "PALFORZIA [package insert]. Brisbane, CA: Aimmune Therapeutics, Inc.",
  "The PALISADE Group of Clinical Investigators: Vickery BP, Vereda A, Casale TB, et al. Supplementary Appendix. N Engl J Med. 2018;379(suppl):1991-2001.",
]

const FOOTNOTES = [
  "Treatment-emergent adverse reactions reported in ≥5% of participants treated with PALFORZIA and ≥5% percentage points greater than reported in participants treated with placebo in any dosing phase (aged 4 through 17 years).",
  "Adverse events were coded to system organ class and preferred term using the MedDRA, version 19.1.",
  "In Study 2, no adverse events ≥5% were reported in participants following treatment with 300 mg PALFORZIA (N=265).",
  "Includes preferred terms of abdominal pain, abdominal pain upper, and abdominal discomfort.",
  "Includes preferred terms of oral pruritus, tongue pruritus, and lip pruritus.",
  "The anaphylactic reaction preferred term includes systemic allergic reactions of any severity, or which severe anaphylaxis was reported in four PALFORZIA-treated participants (0.6%) during Up-Dosing and one PALFORZIA-treated participant (0.3%) during Maintenance.",
]

const Tolerability = ({ data }) => {
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Layout referencesHeader={REFERENCES_HEADER} references={REFERENCES}>
        <SEO title={data.page.title} description={data.page.description} />
        <main className={styles.tolerability}>
          <PageTitle title={"Safety Profile"}></PageTitle>
          <Container fluid as={"section"} className={styles.container}>
            <Container>
              <h2 className={styles.header}>
                Consistent safety profile across clinical studies
              </h2>
              <div className={styles.topicContainer}>
                <h3 className={styles.topicHeader}>
                  Adverse reactions were more frequently reported during
                  Up-Dosing and decreased during Maintenance dosing.
                  <sup className={styles.references}>1</sup>
                </h3>
                <p className={styles.topic}>
                  Study 1 (PALISADE) was a randomized, double-blind,
                  placebo-controlled efficacy and safety study where
                  participants were Up-Dosed for 20 to 40 weeks followed by
                  Maintenance dosing for 24 to 28 weeks.
                </p>
                <p className={styles.topic}>
                  Study 2 (RAMSES) was a randomized, double-blind,
                  placebo-controlled safety study where participants were
                  Up-Dosed for 20 to 40 weeks up to 300 mg daily dose with no
                  extended Maintenance dosing.
                </p>
              </div>
              <h2
                id={"chartHeader"}
                className={classNames([styles.header, "pinHeader"])}
              >
                Most frequently reported adverse reactions in participants aged
                4 through 17 years
                <sup className={styles.references}>1</sup>
                <sup className={styles.footnotes}>*</sup>
              </h2>
              <div className={styles.topicContainer}>
              <p className={styles.topic}>
                  Symptoms during in-office PALFORZIA dosing had a median time to onset of 4 minutes for 71% of participants. Median time to resolution was 37 minutes.
                  <sup className={styles.footnotes}>1</sup>
                </p>
                <p className={styles.topic}>
                  Organized by system organ class/preferred term.
                  <sup className={styles.footnotes}>†</sup>
                </p>
              </div>
              <TolerabilityChart {...tolerabilityData} />
              <p className={styles.chartFooter}>
                At each level of summarization (any event, system organ class,
                or preferred term), participants with more than one adverse reaction
                were counted only once within each study period.
              </p>
              <ol className={styles.footnotesSection}>
                {FOOTNOTES.map((footnote, index) => {
                  return (
                    <li className={styles.footnoteRef} key={index}>
                      <sup className={styles.symbol}>
                        {FOOTNOTE_SYMBOLS[index]}
                      </sup>
                      {footnote}
                    </li>
                  )
                })}
              </ol>
              <p className={styles.acronym}>
                MedDRA = Medical Dictionary for Regulatory Activities.
              </p>
              <h2
                className={classNames([styles.header, "pinHeader"])}
              >
                Eosinophilic Gastrointestinal Disease
              </h2>
              <p className={styles.chartFooter}>
                In clinical studies, 28 of 1050 (2.7%) participants were referred for a gastroenterology evaluation, and 17 of these 28 participants reported undergoing an esophagogastroduodenoscopy (EGD). 
                Of participants who underwent an EGD, 12 were diagnosed with biopsy-confirmed eosinophilic esophagitis while receiving PALFORZIA compared with 0 of 292 (0%) participants receiving placebo. 
                After discontinuation of PALFORZIA, symptomatic improvement was reported in 12 of 12 participants. In 8 participants with available follow-up biopsy results, eosinophilic esophagitis was resolved in 6 participants and improved in 2 participants.
              </p>
            </Container>
          </Container>
        </main>
      </Layout>
    </StateProvider>
  )
}

export default Tolerability

export const query = graphql`
  query {
    page: contentfulHcpPage(label: { eq: "Safety Profile" }) {
      label
      url
      title
      description
    }
  }
`
